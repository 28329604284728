@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.assetView,
.createAsset,
.login,
.logout,
.resetPassword,
.admin {
  display: grid;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


header {
  background-color: black;
  position: absolute;
  top: 0;
  height: 60px;
  margin-left: 45%;
  margin-right: 45%;
  align: center;
  align-content: center;
}
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
  background-color: black;
  z-index: 9998;
}
.App {
  position: absolute;
  left: 15%;
  right: 15%;
}

.App-logo {
  position: absolute;
  top: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  max-height: 55px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


h1 {
  margin-left: 20px;
  color: rgb(120, 10, 10);
  font-weight: bolder;
}

p {
  margin-left: 20px;
  color: darkgrey;
}

.ucase {
  text-transform: uppercase;
}

.modalBox {
  width: 48%;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading,
.drag {
  font-weight: 600;
  color: #191442;
}

.drag {
  margin-top: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.drag .browse {
  display: inline-block;
  color: #fe8d57;
}

.file-label {
  cursor: pointer;
}

.instruction,
.info {
  color: #6f6c78;
}

.info {
  font-size: 12px;
}

.btn-container {
  display: flex;
  border: solid 0.1px;
  border-radius: 5px;
}

.uploadBox {
  padding: 2em;
  border: 2px dashed #7866e3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.upload-icon {
  font-size: 2em;
  color: #7866e3;
}

.fileIcon {
  display: none;
}

.filename {
  font-size: 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.justPutItTopLeft {
  max-height: 2px;
}

.red {
  font-weight: bolder;
  color: red;
}

.orange {
  font-weight: bolder;
  color: orange;

}

.green {
  font-weight: bolder;
  color:rgb(24, 8, 203);

}

.white {

}